import './App.css';
import MainPage from './Components/MainPage';


function App() {
  return (
    <>
    <MainPage />
    </>
  );
}

export default App;
